export const colors = {
  lightBlue: '#1aacf0',
  pink: '#fd7779',
};

export const chartColors = [
  'rgb(65, 71, 155)',
  'rgb(144, 151, 247)',
  'rgb(167, 172, 230)',
  'rgb(202, 204, 232)',
];

export const chartColorsConvertions = [
  'rgb(39 50 199)',
  'rgb(65, 71, 155)',
  'rgb(144, 151, 247)',
  'rgb(167, 172, 230)',
];

export const chartColorsConvertions2 = [
  'rgb(0 5 72)',
  'rgb(65, 71, 155)',
  'rgb(144, 151, 247)',
  'rgb(167, 172, 230)',
];

export const chartColorsList = [
  'rgb(39 50 199)',
  'rgb(0 5 72)',
  'rgb(65, 71, 155)',
  'rgb(144, 151, 247)',
  'rgb(167, 172, 230)',
];

export const funnelColors = [
  {
    background: '#C5C4FD', // purple
    color: '#2521F7',
  },
  {
    background: '#CEF3D6', // green
    color: '#1F8035',
  },
  {
    background: '#FFF2C2', // yellow
    color: '#F5C200',
  },
  {
    background: '#CEF3D6', // green
    color: '#1F8035',
  },
  {
    background: '#C5C4FD', // purple
    color: '#2521F7',
  },
];

export const pieChartColors = ['#075378', '#0E9CE2', '#9097F7'];

export const defaultColorCompany = '#58c1f5';
