import { notification } from 'antd';
import Lottie from 'lottie-react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getMe } from '../../API/services/authService';
import {
  createWorkspaceAsync,
  getAllWorkspaces,
  getWorkspaceOwners,
  leaveWorkspace,
  setFavoriteWorkspace,
} from '../../API/services/screeningService';
import PlusRounded from '../../assets/cardWorkspace/PlusRounded';
import EmptyState from '../../assets/screening/EmptyState.json';
import CardWorkspace from '../../components/CardWorkspace/CardWorkspace';
import DropdownSelect from '../../components/DropdownSelect/DropdownSelect';
import FormInputSearch from '../../components/FormInputSearch/FormInputSearch';
import HomeProfile from '../../components/HomeProfile/HomeProfile';
import HorizontalLoader from '../../components/HorizontalLoader/HorizontalLoader';
import ModalCustom from '../../components/ModalCustom/ModalCustom';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import Spinner from '../../components/spinner/Spinner';
import { CREATED_WORKSPACE } from '../../constants/routes';
import useClickOutside from '../../hooks/useClickOutside';
import { useMissingData } from '../../hooks/useMissingData';
import useOnScreen from '../../hooks/useOnScreen';
import { t } from '../../i18n/i18n';
import {
  setCurrentWorkspace,
  setWorkspaceOwners,
  setWorkspacesList,
  setUser,
  setCurrentRole,
} from '../../redux/actions/authActions';
import { setLocalStorageWorkspace } from '../../utils/workspaceUtils';
import './Workspace.scss';
import { PartnerWorkspaceRole } from '../../constants/enums';

const Workspace = () => {
  const percentage = useMissingData();
  const dispatch = useDispatch();
  const { user, workspacesList, workspaceFavorite, currentWorkspace } = useSelector(
    (state) => state.auth
  );
  const [searchWorkspace, setSearchWorkspace] = useState('');
  const [workspaces, setWorkspaces] = useState([]);
  const [workspaceName, setWorkspaceName] = useState('');
  const [selectOwner, setSelectOwner] = useState();
  const [showLeaveWorkspaceModal, setShowLeaveWorkspaceModal] = useState(false);
  const [leaveWorkspaceData, setLeaveWorkspaceData] = useState({});
  const [openModalCreateWorkspace, setOpenModalCreateWorkspace] = useState(false);
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const [loadingNextPage, setloadingNextPage] = useState(false);
  const [optionsOwners, setOptionsOwners] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 6 });
  const [nextPage, setNextPage] = useState(null);
  const [createWorkspaceLoader, setCreateWorkspaceLoader] = useState(false);

  const ref = useRef(null);
  useClickOutside(ref, () => setOpenModalCreateWorkspace(false));
  const fetchNextUrlRef = useRef(null);
  const fetchNextUrl = useOnScreen(fetchNextUrlRef, '0px', true);

  const handleRequest = async (query) => {
    try {
      setNextPage(null);
      setloading(true);
      const { collection, pagination } = await getAllWorkspaces(query);
      if (user?.workspaces) {
        const rolesByWorkspace = {};

        user?.workspaces.forEach((workspace) => {
          rolesByWorkspace[workspace.workspaceId] = workspace.role;
        });

        const collectionWithWorkspaceRole = collection.map((workspace) => {
          if (rolesByWorkspace[workspace?.id]) {
            return { ...workspace, workspaceRole: rolesByWorkspace[workspace?.id] };
          }
          return workspace;
        });
        setWorkspaces(collectionWithWorkspaceRole);
      } else {
        setWorkspaces(collection);
      }
      setNextPage(pagination.nextPage);
      setloading(false);
    } catch (error) {
      notification.open({
        message: t('REQUEST_WORKSPACES_ERROR'),
        type: 'error',
        duration: 3,
      });
    }
  };

  const handleNextPage = async (query) => {
    try {
      setloadingNextPage(true);
      const { collection, pagination } = await getAllWorkspaces(query);
      if (user?.workspaces) {
        const rolesByWorkspace = {};

        user?.workspaces.forEach((workspace) => {
          rolesByWorkspace[workspace.workspaceId] = workspace.role;
        });

        const collectionWithWorkspaceRole = collection.map((workspace) => {
          if (rolesByWorkspace[workspace?.id]) {
            return { ...workspace, workspaceRole: rolesByWorkspace[workspace?.id] };
          }
          return workspace;
        });
        setWorkspaces((prevData) => [...prevData, ...collectionWithWorkspaceRole]);
      } else {
        setWorkspaces((prevData) => [...prevData, ...collection]);
      }
      setNextPage(pagination.nextPage);
      setloadingNextPage(false);
    } catch (error) {
      notification.open({
        message: t('REQUEST_WORKSPACES_ERROR'),
        type: 'error',
        duration: 3,
      });
    }
  };

  const clearFilters = async () => {
    const query = {
      offset: 0,
      limit: 6,
      search: '',
      owner: '',
    };
    setSearchWorkspace('');
    setSelectOwner();
    await handleRequest(query);
  };

  const addFavorite = async (id) => {
    try {
      await setFavoriteWorkspace({
        id,
        favorite: true,
      });
      setWorkspaces(
        workspaces?.map((workspace) => {
          if (workspace.id === id) {
            return { ...workspace, favorite: true };
          }
          return workspace;
        })
      );
      dispatch(
        setWorkspacesList(
          workspacesList?.map((workspace) => {
            if (workspace.id === id) {
              return { ...workspace, favorite: true };
            }
            return workspace;
          })
        )
      );
    } catch (error) {
      notification.open({
        message: t('SET_FAVORITE_WORKSPACE_ERROR'),
        type: 'error',
        duration: 3,
      });
    }
  };

  const removeFavorite = async (id) => {
    try {
      await setFavoriteWorkspace({
        id,
        favorite: false,
      });
      setWorkspaces(
        workspaces?.map((workspace) => {
          if (workspace.id === id) {
            return { ...workspace, favorite: false };
          }
          return workspace;
        })
      );
      dispatch(
        setWorkspacesList(
          workspacesList?.map((workspace) => {
            if (workspace.id === id) {
              return { ...workspace, favorite: false };
            }
            return workspace;
          })
        )
      );
    } catch (error) {
      notification.open({
        message: t('REMOVE_FAVORITE_WORKSPACE_ERROR'),
        type: 'error',
        duration: 3,
      });
    }
  };

  const searchWorkspaceByName = async () => {
    const query = {
      offset: 0,
      limit: 6,
      search: searchWorkspace,
      owner: selectOwner,
    };
    await handleRequest(query);
  };

  const getOwners = async () => {
    const owners = await getWorkspaceOwners();
    const uniqueOwners = {};
    owners.forEach((w) => {
      const id = w.ownerBy;
      const name = `${w.ownerBy === user.id ? t('ME') : ''} ${w.ownerFirstName} ${w.ownerLastName}`;
      uniqueOwners[id] = { id, name };
    });

    const uniqueOwnersArray = Object.values(uniqueOwners);
    setOptionsOwners(uniqueOwnersArray);
    dispatch(setWorkspaceOwners(owners));
  };

  useEffect(() => {
    handleRequest({ ...pagination });
    getOwners();
  }, []);

  useEffect(() => {
    if (nextPage && fetchNextUrl && !loading) {
      setPagination({
        offset: (pagination.offset += pagination.limit),
        limit: pagination.limit,
      });
      handleNextPage({ ...pagination });
    }
  }, [fetchNextUrl, nextPage, loading]);

  const handleCreateWorkspace = async ({ name }) => {
    const workspaceData = {
      name: name ?? workspaceName,
      companyId: user.companyId, // a Partner can only create workspace for his own company
    };
    setCreateWorkspaceLoader(true);
    const res = await createWorkspaceAsync(workspaceData);

    if (res) {
      const newWorkspace = {
        id: res.workspaceId,
        favorite: false,
        color: '',
        name: name ?? workspaceName,
        icon: '',
        iconId: null,
        role: PartnerWorkspaceRole.OWNER,
        companyId: user.companyId,
      };
      const userData = await getMe();
      dispatch(setUser(userData));
      dispatch(setCurrentWorkspace(res.workspaceId));
      dispatch(setCurrentRole(PartnerWorkspaceRole.OWNER));
      dispatch(setWorkspacesList([...workspacesList, newWorkspace]));
      setOpenModalCreateWorkspace(false);
      setWorkspaceName('');
      setCreateWorkspaceLoader(false);
      history.push(CREATED_WORKSPACE);
    } else {
      setOpenModalCreateWorkspace(false);
      setCreateWorkspaceLoader(false);
      setWorkspaceName('');
      notification.open({
        message: t('ERROR_TO_CREATE_WORKSPACE'),
        type: 'error',
      });
    }
  };

  const setActiveWorkspace = (id) => {
    const workspaceData = workspacesList.find((workspace) => workspace.id === id);
    const role = user.workspaces.filter((item) => item?.workspace?.id === id)[0]?.role;
    setLocalStorageWorkspace({
      id,
      favorite: workspaceData.favorite,
      name: workspaceData.name,
      icon: workspaceData.icon,
      color: workspaceData.color,
      iconId: workspaceData.iconId,
    });
    dispatch(setCurrentWorkspace(id));
    dispatch(setCurrentRole(role));
  };

  const openLeaveWorkspaceModal = (workspaceData) => {
    setShowLeaveWorkspaceModal(true);
    setLeaveWorkspaceData(workspaceData);
  };

  const handleLeaveWorkspace = async (workspaceId) => {
    if (workspaces.length === 1) {
      await handleCreateWorkspace({ name: 'Tu espacio' });
    }
    await leaveWorkspace(workspaceId)
      .then(() => {
        setWorkspaces(workspaces?.filter((workspace) => workspace.id !== workspaceId));
        dispatch(
          setWorkspacesList(workspacesList?.filter((workspace) => workspace.id !== workspaceId))
        );
        notification.open({
          message: t('LEAVE_WORKSPACE_SUCCESS'),
          type: 'success',
          duration: 3,
        });
      })
      .catch(() => {
        notification.open({
          message: t('LEAVE_WORKSPACE_ERROR'),
          type: 'error',
          duration: 3,
        });
      })
      .finally(() => {
        setLeaveWorkspaceData({});
        setShowLeaveWorkspaceModal(false);
      });
  };

  useEffect(() => {
    if (workspaceFavorite) {
      handleRequest({ ...pagination });
    }
  }, [workspaceFavorite]);

  return (
    <div className="home-container mt-5 bg-gray px-5">
      {showLeaveWorkspaceModal && (
        <ModalCustom
          datatestid="modal-leave-workspace"
          datatestidbuttonok="leave-workspace-button-ok"
          title={t('LEAVE_WORKSPACE_MODAL_TITLE').replace(
            '{workspaceName}',
            leaveWorkspaceData?.name
          )}
          subtitle={t('LEAVE_WORKSPACE_MODAL_SUBTITLE')}
          show={showLeaveWorkspaceModal}
          setShow={setShowLeaveWorkspaceModal}
          onOkText={t('LEAVE_WORKSPACE_MODAL_OK_BUTTON')}
          onCancelText={t('LEAVE_WORKSPACE_MODAL_CANCEL_BUTTON')}
          onCancel={() => {
            setShowLeaveWorkspaceModal(false);
            setLeaveWorkspaceData({});
          }}
          onOk={() => handleLeaveWorkspace(leaveWorkspaceData?.id)}
          classModal="modal-create-workspace"
          showCross={false}
          isLoading={createWorkspaceLoader}
        />
      )}
      {openModalCreateWorkspace && (
        <ModalCustom
          datatestid="modal-create-workspace"
          datatestidbuttonok="button-ok"
          title={t('CREATE_WORKSPACE')}
          htmlSubtitle
          show={openModalCreateWorkspace}
          setShow={setOpenModalCreateWorkspace}
          onOkText={t('CREATE')}
          onCancelText={t('CANCEL')}
          onCancel={() => setOpenModalCreateWorkspace(false)}
          onOk={handleCreateWorkspace}
          classModal="modal-create-workspace"
          showCross={false}
          isLoading={createWorkspaceLoader}
          input={
            <input
              ref={(ref) => ref && ref.focus()}
              className="input-name-workspace input outline-none px-3 py-2 font-montserrat input-search text-base mx-auto mt-3 mb-2"
              onChange={(e) => setWorkspaceName(e.target.value)}
              type="text"
              value={workspaceName}
              placeholder={t('CREATE_WORKSPACE_INPUT_NAME')}
            />
          }
        />
      )}
      <div className="home-display-content container-margin">
        {percentage < 100 && <HomeProfile workspacePage />}
        <div className={`${percentage < 100 ? 'mt-5' : 'mt-2'}`}>
          <h1 className="font-montserrat font-weight-600 text-base">
            {t('WORKSPACE_PAGE_BANNER')}
          </h1>
        </div>
        <div className="d-flex justify-content-between ml-3 button-create-workspace">
          <div className="d-flex row my-3 align-items-center mr-4">
            <FormInputSearch
              searchText={searchWorkspace}
              setSearchText={setSearchWorkspace}
              onSubmit={searchWorkspaceByName}
              onClear={clearFilters}
              placeholder={t('WORKSPACE_INPUT_SEARCH')}
              className="size-input flex-fill"
            />
            <DropdownSelect
              selectedPlaceholder
              className="ml-5 b-radius-8 dropdown-border size-input mb-1 flex-fill"
              placeholder={t('DROPDOWN_WORKSPACE_PLACEHOLDER')}
              placeholderClassname={`${
                selectOwner ? 'font-weight-500' : 'dropdown-input-search-placeholder'
              } w-100 text-xs font-montserrat`}
              setState={(e) => {
                setSelectOwner(e.id);
                handleRequest({ offset: 0, limit: 12, search: searchWorkspace, owner: e.id });
              }}
              selected={selectOwner}
              options={optionsOwners}
              outsidePadding={false}
              alignCenter
              arrowColor="#000000"
              menuClassnames="b-radius-8"
            />
            {(selectOwner || Boolean(searchWorkspace)) && (
              <button
                data-testid="button-clear-filters"
                className="ml-3 font-montserrat font-weight-bold text-xs text-white px-3 py-2 bg-transparent text-maya-blue maya-blue-thin-border rounded-pill"
                type="button"
                onClick={clearFilters}
              >
                {t('PROFILES_CLEAR_FILTERS')}
              </button>
            )}
          </div>
          <button
            data-testid="button-create-workspace"
            className="mt-3 font-montserrat font-weight-bold border-0 text-xs text-white px-3 py-2 bg-maya-blue rounded-pill h-25"
            type="button"
            onClick={() => setOpenModalCreateWorkspace(true)}
          >
            {t('CREATE_WORKSPACE')}
          </button>
        </div>
        {workspaces.length && !loading ? (
          <div className="mt-3 d-flex row ml-1">
            {workspaces.map((workspace) => (
              <CardWorkspace
                key={`${workspace.id}-card`}
                workspace={workspace}
                addFavorite={addFavorite}
                removeFavorite={removeFavorite}
                setActiveWorkspace={setActiveWorkspace}
                handleLeaveWorkspace={openLeaveWorkspaceModal}
              />
            ))}
            {workspaces.length <= 2 && (
              <div className="size-card d-flex flex-column justify-content-center align-items-center b-radius-8 border-dashed">
                <button
                  type="button"
                  data-testid="empty-card-button-create-workspace"
                  className="bg-transparent border-0 d-flex flex-column align-items-center"
                  onClick={() => setOpenModalCreateWorkspace(true)}
                >
                  <PlusRounded />
                  <span className="mt-2 font-montserrat font-weight-600">
                    {t('CREATE_WORKSPACE')}
                  </span>
                </button>
              </div>
            )}
            <ScrollToTop />
          </div>
        ) : (
          !loading && (
            <div className="w-50 mx-auto text-center">
              <div className="empty-state-container mx-auto mb-4">
                <Lottie animationData={EmptyState} loop className="my-5" />
                <p className="font-weight-600 font-montserrat px-2 text-base">
                  {t('NOT_RESULTS_FOUND_WORKSPACES')}
                </p>
                <p className="font-montserrat font-weight-400 text-base m-0">
                  {t('NOT_RESULTS_FOUND_WORKSPACES_DESCRIPTION')}
                </p>
              </div>
            </div>
          )
        )}
        <Spinner show={loading && !loadingNextPage} />
        {loadingNextPage && (
          <div
            data-testid="workspace-page-horizontal-loader"
            className="d-flex justify-content-center position-absolute pt-4 pr-5 left-0 right-0 mt-0 mx-auto bg-gray"
          >
            <HorizontalLoader className="mt-2" />
            <span className="font-montserrat font-weight-500 text-disabled">
              {t('LOADING_MORE')}
            </span>
          </div>
        )}
        {Boolean(workspaces.length) && <div ref={fetchNextUrlRef} />}
      </div>
    </div>
  );
};

export default Workspace;
