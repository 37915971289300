import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'lottie-react';
import useCreateScreening from '../../../hooks/useCreateScreening';
import Spinner from '../../spinner/Spinner';
import timerIcon from '../../../assets/screening/timer.svg';
import EmptyState from '../../../assets/screening/EmptyState.json';
import HorizontalLoader from '../../HorizontalLoader/HorizontalLoader';
import { t } from '../../../i18n/i18n';
import useOnScreen from '../../../hooks/useOnScreen';
import TestItem from '../../TestItem/TestItem';
import { formatMinutesToHours } from '../../../utils/formatMinutesToHours';
import { TrackEvent } from '../../../utils/filters/segmentUtils';

const AlkemyTests = ({ loadingTests, fetchTests, setShow, isCheck, evaluation }) => {
  const {
    loading,
    testArray,
    amountOfFilters,
    testsDuration,
    handleClearFilters,
    fetchNextPage,
    handleTests,
    newScreening,
  } = useCreateScreening();
  const fetchNextUrlRef = useRef();
  const fetchNextUrl = useOnScreen(fetchNextUrlRef, '0px', true);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    fetchTests({
      persistFilters: true,
      ...(evaluation?.roleId ? { rolesIds: evaluation.roleId } : {}),
    });
  }, []);

  useEffect(() => {
    if (!fetchNextUrl) setFetched(false);
    if (
      fetchNextUrl &&
      !fetched &&
      testArray?.collection?.length &&
      testArray?.pagination?.nextPage &&
      !loading &&
      !loadingTests
    ) {
      fetchNextPage();
      setFetched(true);
    }
  }, [fetchNextUrl]);

  return (
    <div data-testid="create-evaluation-alkemy-test">
      {!loadingTests && (
        <>
          <div className="d-flex justify-content-between pb-2">
            <div className="d-flex">
              {amountOfFilters > 0 && (
                <button
                  onClick={() => handleClearFilters()}
                  className="create-evaluation-secondary-button ml-2 font-montserrat font-weight-700 bg-transparent py-2 px-4 text-xs"
                  type="button"
                  data-testid="alkemy-tests-clear-filters-btn"
                >
                  {t('PROFILES_CLEAR_FILTERS')}
                </button>
              )}
            </div>
            {isCheck && (
              <div className="my-1 my-sm-0 mr-0 mr-sm-2 d-flex align-items-center ml-2">
                <img
                  src={timerIcon}
                  className="mr-1"
                  alt={t('TIMER_ALT')}
                  data-testid="alkemy-tests-timer-img"
                />
                <span
                  className="font-montserrat text-muted text-base font-weight-500"
                  data-testid="alkemy-tests-time-title-span"
                >
                  {t('CREATE_SCREENING_TOTAL_TIME')}
                </span>
                <span
                  className="total-number-text font-montserrat text-base ml-1 font-weight-bold"
                  data-testid="alkemy-tests-time-duration-span"
                >
                  {formatMinutesToHours(testsDuration)}
                </span>
              </div>
            )}
          </div>
        </>
      )}
      {!testArray?.collection?.length && !loading && !loadingTests && (
        <div className="w-100 bg-white mt-3">
          <div className="w-75 d-flex flex-column align-items-center mx-auto text-center">
            <div
              data-testid="create-evaluation-alkemy-tests-lottie"
              className="empty-state-container mx-auto mb-2"
            >
              <Lottie animationData={EmptyState} loop className="my-5" />
            </div>
            <div className="alkemy-tests-empty-state-texts">
              <p
                className="font-weight-600 font-montserrat px-2 text-base"
                data-testid="alkemy-tests-empty-state-title-p"
              >
                {t('CREATE_EVALUATION_ALKEMY_TESTS_EMPTY_STATE_TITLE')}
              </p>
              <p
                className="font-weight-400 mt-2 mb-4 font-montserrat px-2 text-base"
                data-testid="alkemy-tests-empty-state-subtitle-p"
              >
                {t('CREATE_EVALUATION_ALKEMY_TESTS_EMPTY_STATE_SUBTITLE')}
              </p>
              <a
                className="font-montserrat font-weight-500 font-montserrat text-sm text-muted create-evaluation-gray-underline"
                onClick={() => {
                  TrackEvent('suggest-test-link');
                }}
                href={process.env.RAZZLE_RUNTIME_TEST_SUGGESTIONS_LINK}
                rel="noopener noreferrer"
                target="_blank"
                data-testid="alkemy-tests-empty-state-link-a"
              >
                {t('CREATE_EVALUATION_ALKEMY_TESTS_EMPTY_STATE_ANCHOR')}
              </a>
            </div>
          </div>
        </div>
      )}
      <div
        data-testid="create-evaluation-alkemy-tests-list"
        className={`px-2 px-md-4 py-4 overflow-auto bg-gray ${
          loadingTests ? ' d-flex justify-content-center' : ' px-2 px-md-4 py-4'
        }
         ${
           (testArray?.collection?.length || loading) &&
           'test-grid-container test-grid-container-tests'
         }
        `}
        style={{ height: '32rem' }}
      >
        {loadingTests ? (
          <Spinner show />
        ) : (
          <>
            {testArray?.collection?.map((item) => (
              <TestItem
                key={item.id}
                test={item}
                setShow={setShow}
                isStar
                isFavorite={item?.favorite ?? false}
                testArray={testArray}
                isCheck={isCheck}
                handleTests={handleTests}
                newScreening={newScreening}
              />
            ))}
          </>
        )}
        <div ref={fetchNextUrlRef} />
      </div>
      {loading && (
        <div
          data-testid="create-evaluation-alkemy-tests-horizontal-loader"
          className="d-flex justify-content-center position-absolute pt-4 pr-5 left-0 right-0 horizontal-loader-height mt-0 mx-auto"
        >
          <HorizontalLoader />
        </div>
      )}
    </div>
  );
};

export default AlkemyTests;
