import React, { useEffect } from 'react';
import { t } from '../../i18n/i18n';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import GenericToggle from '../GenericToggle/GenericToggle';
import useCreateScreening from '../../hooks/useCreateScreening';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import Spinner from '../spinner/Spinner';

const CreateEvaluationFirstStep = ({
  evaluation,
  handleEvaluationName,
  handleEvaluationQuickRole,
  handleSetEvaluationData,
  quickTest,
  setQuickTest,
}) => {
  const {
    testGroupRolesFilterOptions,
    fetchRoles,
    testGroupQuickRolesOptions,
    fetchQuickRolesWithTestGroups,
  } = useCreateScreening();
  const handleSetEvaluationQuickRole = (e) => {
    handleEvaluationQuickRole(e.id);
  };

  useEffect(() => {
    if (!testGroupRolesFilterOptions?.length) {
      fetchRoles();
    }
    if (!testGroupQuickRolesOptions?.length) {
      fetchQuickRolesWithTestGroups();
    }
    handleSetEvaluationData({
      ...evaluation,
      startDate: {
        day: new Date().toISOString(),
        hours: '00:00',
      },
      endDate: {
        day: new Date('9999-01-01').toISOString(),
        hours: '00:00',
      },
      allowCertificateDownload: true,
    });
  }, []);

  const handleSelectRole = (role) => {
    handleSetEvaluationData({
      ...evaluation,
      customRole: null,
      roleId: role?.id,
    });
  };

  return (
    <div data-testid="create-evaluation-first-step" className="d-flex flex-column">
      <span
        className="font-weight-600 font-montserrat create-evaluation-step-title text-light-black mb-3"
        data-testid="create-evaluation-fs-title-span"
      >
        {t('CREATE_EVALUATION_FIRST_STEP_TITLE')}
      </span>
      <span
        className="font-montserrat font-weight-600 text-base text-light-black"
        data-testid="create-evaluation-fs-first-step-span"
      >
        {t('CREATE_EVALUATION_FIRST_STEP_TEST_NAME_TITLE')}
      </span>
      <span
        className="text-light-black font-weight-400 text-base my-2 font-montserrat"
        data-testid="create-evaluation-fs-second-step-span"
      >
        {t('CREATE_EVALUATION_FIRST_STEP_TEST_NAME_SUBTITLE')}
      </span>
      {Boolean(testGroupRolesFilterOptions.length) ? (
        <>
          <div className="d-flex align-items-center mt-2">
            <input
              minLength={1}
              maxLength={50}
              placeholder={t('SCREENING_NAME_INPUT_PLACEHOLDER')}
              value={evaluation?.name}
              type="text"
              onChange={handleEvaluationName}
              className={`p-2 font-montserrat rounded text-sm screening-name-input evaluation-name-input mr-2 ${
                evaluation?.name?.trim()?.length >= 1 && 'completed'
              }`}
              data-testid="create-evaluation-fs-name-input"
            />
          </div>
          <span
            className="font-montserrat mt-3 font-weight-600 text-base py-2 text-light-black"
            data-testid="create-evaluation-fs-date-title-span"
          >
            {t('CREATE_EVALUATION_ROLE')}
          </span>

          <DropdownSelect
            options={(testGroupRolesFilterOptions ?? []).map((item) => ({
              id: item.id,
              name: t(item?.whitelabelName),
            }))}
            className="first-step-role-dropdown w-100"
            selected={evaluation?.roleId}
            maxHeight={false}
            arrowPurple
            arrow={false}
            placeholder={t('CREATE_EVALUATION_ROLE_TESTS_INPUT_PLACEHOLDER')}
            searchable
            setState={async (e) => {
              handleSelectRole(e);
            }}
            selectedPlaceholder
            alignCenter={false}
          />
          <div className="my-3">
            <div className="d-flex align-items-center">
              <span
                className="font-montserrat font-weight-600 text-base py-2 text-light-black"
                data-testid="create-evaluation-fs-download-certificate-span"
              >
                {t('CREATE_EVALUATION_OPTIONAL_CONFIGURATION')}
              </span>
            </div>
            <div className="d-flex mt-2 align-items-center">
              <span className="font-montserrat font-weight-500 text-light-black text-base">
                {t('CREATE_EVALUATION_QUICK_ROLE_TITLE')}
              </span>
              <GenericToggle
                data-testid="toggle"
                defaultActive={quickTest}
                actionFunction={() => {
                  if (quickTest) {
                    handleSetEvaluationQuickRole({ id: null });
                  }
                  setQuickTest(!quickTest);
                }}
              />
              <InfoTooltip
                placement="top"
                content={
                  <div className="d-flex flex-column py-2">
                    <span className="font-montserrat text-xs mb-2">
                      {t('CREATE_EVALUATION_QUICK_ROLE_INFO')}
                    </span>
                  </div>
                }
              />
            </div>
            {quickTest && testGroupQuickRolesOptions && (
              <div className="d-flex align-items-center mt-4">
                <span className="font-montserrat text-light-black font-weight-400 text-sm mr-2">
                  {t('CREATE_EVALUATION_QUICK_ROLE_TEXT')}
                </span>
                <DropdownSelect
                  outsidePadding={false}
                  options={(testGroupQuickRolesOptions ?? []).map((item) => ({
                    id: item.id,
                    name: item?.displayName,
                  }))}
                  selected={evaluation.quickStartRoleId}
                  className="mr-2 evaluation-purpose-select"
                  setState={handleSetEvaluationQuickRole}
                  selectedPlaceholder
                  placeholder={t('CREATE_EVALUATION_QUICK_ROLE_PLACEHOLDER')}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <Spinner show />
      )}
    </div>
  );
};

export default CreateEvaluationFirstStep;
